<template>
<div class="createldialog" >
  <el-dialog  :visible.sync="createdialong"  width="33%"   :before-close="clear" :close-on-click-modal="false"
  
   >
    <div class="result" v-loading="loading"   element-loading-text="拼命加载中"   element-loading-background="rgba(255,255,255,0.8)">
      <el-form ref="resultApp" :model="resultApp" label-width="170px">
        <el-form-item label="公司名称">
          <div class="inputDeep">
            <el-input v-model="resultApp.coname"  placeholder="请输入..."  class="attestation"></el-input>
          </div>
        </el-form-item>
        <!-- 主图 -->
        <el-form-item label="公司logo">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :headers="httpheader"
             :on-progress = "oprogress"
              :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="resultApp.coimage"
              :src="resultApp.coimage"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- 姓名法定代表人 -->
        <el-form-item label="法定代表人" class="peoplecode">
          <el-input v-model="resultApp.lpname" placeholder="请输入..." ></el-input>
        </el-form-item>
        <!-- 姓名法定代表人身份证 -->
        <el-form-item label="法定代表人身份证" class="identitycode">
          <el-input v-model="resultApp.idcard" placeholder="请输入..." ></el-input>
        </el-form-item>
        <!-- 企业社会统一信用代码 -->
        <el-form-item label="企业社会统一信用代码" class="creditcode">
          <el-input v-model="resultApp.creditcode" placeholder="请输入..." ></el-input>
        </el-form-item>
        <!-- 地址 -->
         
        <el-form-item label="公司地址">
          <el-cascader
            v-model="resultApp.coaddress"
            :options="options"
            clearable
             @change="coaddressChange"
          ></el-cascader>
        </el-form-item>
        <!-- 街道 -->

        <el-form-item label="公司街道">
         
           <el-input v-model="resultApp.costreet" placeholder="请输入..." class="attestation" ></el-input>
        </el-form-item>
        <!-- 联系方式 -->
        <el-form-item label="公司电话">
          <el-input v-model="resultApp.cocontact" placeholder="请输入公司座机或联系人手机号码" ></el-input>
        </el-form-item>
        <!-- 证件类型 -->
        <el-form-item label="证件类型">
          <div class="five">
            <el-radio v-model="resultApp.certtype" label="0">三证合一</el-radio>
            <el-radio v-model="resultApp.certtype" label="1">五证合一</el-radio>
          </div>
        </el-form-item>
        <!-- 法人手机号 -->
           <el-form-item label="经办人手机号" class="lpiphonecode">
          <el-input v-model="resultApp.lpphone" placeholder="请输入经办人手机号" ></el-input>
        <!-- <el-button type="danger" size="small " round  @click="sendVerifyCode" class="codeeee">获取验证码</el-button> -->
        </el-form-item>
        <!-- 验证码 -->
         <!-- <el-form-item label="验证码">
          <el-input v-model="resultApp.veritycode" placeholder="请输入验证码" ></el-input>
        </el-form-item> -->
        <!-- 营业执照 -->
        <el-form-item label="营业执照">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="cohandleAvatarSuccess"
            :headers="httpheader"
             :before-upload="beforeAvatarUpload"
              :on-progress = "oprogress"
          >
            <img
              v-if="resultApp.colicense"
              :src="resultApp.colicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 企业认证授权书 -->
        <el-form-item label="企业认证授权书" class="dobble">
           <el-link
           v-if="companyAutcer"
                  :href="companyAutcer"
            
      
                  style="margin-left: 30px"
                >
                  下载
                </el-link>
         
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="ahandleAvatarSuccess"
            :headers="httpheader"
              :before-upload="beforeAvatarUpload"
               :on-progress = "oprogress"
          >
           <p id="" v-if="resultApp.authorization">重新上传</p>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i> 
          </el-upload>
       
        </el-form-item>

        <!-- 注册资金 -->
        <el-form-item label="注册资金">
          <el-input v-model="resultApp.funding" class="wan" placeholder="请输入注册资金"></el-input><span class="wanwan">万</span>
        </el-form-item>
        <!-- 主营 -->
        <el-form-item label="公司主营">
          <el-input v-model="resultApp.mainscope" placeholder="请认真填写,此内容将在公司主页展示" ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <div>
        <el-button type="danger" @click="createsure" class="sureboom">确认提交</el-button>
      </div>
      <div>
        <el-checkbox v-model="checked" :checked="checked"
          >我已阅读并同意平台企业行为规范协议</el-checkbox
        >
        <a @click="companyAut">查看协议</a>
      </div>
         <Goodsure :addDialog="addDialog" @del="del" ref="child" @addsure="addsure"></Goodsure>
    </div>
  </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui";
import companyApi from "../../../api/companyApi"
import cityCode from "../../../unitls/city_code.js"
import Goodsure from "../entre/goodsurelog.vue";
import basecfg from "../../../utils/applocationCfg"
import imageZip from "../../../utils/imageZip"
import administratorApi from "../../../api/administrator"
export default {
  data() {
    return {
        baseApi:basecfg.getBaseApi(),
      companyAutcer:"",
        addDialog: false,
         loading:false,
      httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
      // 公司主图
      imageUrl: "",
      // 选中
      checked: false,
      // 申请表单
      resultApp: {
        authorization: "",
        certtype: '0',
        cocontact: "",
        coaddress:[],
        coimage: "",
        colicense: "",
        coname: "",
        costreet: "",
        creditcode: "",
        funding: '',
        idcard: "",
        lpname: "",
        mainscope: "",
      },
      //  checked:false,
      // 三级联动
 

      // --------------地区----\
      options:[],
      // ----------------
      name: "上海钢铁贸有限公司",
      labelPosition: "left",
    };
  },
  props: {
    createdialong: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    Goodsure
  },
  mounted(){
    this.options = cityCode

    this.companyAutcertificate()
  },
  created(){
 
  },

  methods: {
  // 限制大小
         beforeAvatarUpload(file) {
        const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
        
        if (!isJPG) {
         Message.error('请正确上传图片格式!');
         
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
         
        }
    return  imageZip.beforeUpload(file)
      
    },
      del(bool) {
      this.addDialog = bool;
    },
     addsure(bool) {
      this.addDialog = bool;
    },
      //企业认证协议
    companyAut(){
       this.addDialog = true;
        this.$store.commit("intagreement",'companyAut')
         this.$refs.child.getAgreement()
    },

      //企业认证授权书
    companyAutcertificate(){
       companyApi.getAgreement({agreementId:"companyAutcertificate"},res=>{
       
         this.companyAutcer = res.data.result
       })
    },
    // 获取企业认证手机验证码
    sendVerifyCode(){
       if(!/^1\d{10}$/.test(this.resultApp.lpphone)){
         Message.error('法人手机号格式不正确');
          this.phone=""
         return
       
      }
      companyApi.sendVerifyCode({phone:this.resultApp.lpphone},res=>{
        if(res.data.code==200){
             Message.success("发送成功")
        }
        else{
           Message.error("发送失败")
        }
        
      })
    },
      // 上传时
    oprogress(){
      this.loading = true
    },
    clear() {
      this.$emit("clear", false);
      this.resultApp = {}
    },
    // 确定并提交
    createsure() {
    
      if (this.checked == false) {
       return   Message.error("点击同意尾易网企业行为规范协议，才可以添加企业");
       
      } else {
         if(!this.resultApp.coimage){
               Message.error('请上传公司主图');
                 return
      }
        // 身份证验证
          let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if(reg.test(this.resultApp.idcard) === false) 
       { 
       return   Message.error("身份证不合法")
              ; 
        
           } 
           var regcode =/^((0\d{2,3}-\d{7,8})|(0\d{2,3}\d{7,8})|(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8}))$/;
     
         if(regcode.test(this.resultApp.cocontact) === false) 
       { 
       return   Message.error("公司电话填写格式不正确")
              ; 
        
           } 
        if(!/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(this.resultApp.lpphone)){
         Message.error('经办人手机号格式不正确');
          this.phone=""
         return
       
      }
      if(!this.resultApp.authorization){
               Message.error('请上传企业认证授权书');
                 return
      }
        this.$uweb.trackEvent("认证行为","点击","企业认证")
         companyApi.createCompany(this.resultApp,res=>{
          if(res.data.code==200){ 
              this.$uweb.trackEvent("认证行为","认证成功","企业认证")
               Message.success("创建企业成功");      
             this.$emit("createsure", false); 
             this.$router.push("entrepreneur")   
            this.$router.go(0)   
          }
         else{ 
            Message.error(res.data.message);      
          }
           this.loading=false
        })
      }
    },
    //公司主图
    handleAvatarSuccess(res, file) {
       this.loading = false
         if(res.code==200){
            this.resultApp.coimage =res.result
         }
         else{
         Message.error(res.message);
         }
     
    },
    // 营业执照
    cohandleAvatarSuccess(res, file) {
       this.loading = false
         if(res.code==200){
            this.resultApp.colicense =res.result
         }
         else{
        Message.error(res.message);
         }
     
    },
    // 企业认证授权书
    ahandleAvatarSuccess(res, file) {
             this.loading = false
          if(res.code==200){
         this.resultApp.authorization = res.result
          }
          else{
               Message.error(res.message);
          }
         
         
    },
 
    // 公司地址
     coaddressChange(label) {
        this.resultApp.coaddress =label
      },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader p {
  width: 100px !important;
  margin-left: -40px;
}

.createldialog ::v-deep .el-dialog{
  min-width: 477px;
} 
.peoplecode ::v-deep.el-form-item__label {
  // border: 1px solid red;
  width: 120px !important;
  display: inline-block;
}
.identitycode ::v-deep.el-form-item__label {
  // border: 1px solid red;
  width: 170px !important;
  display: inline-block;
}
.dobble ::v-deep.el-form-item__label {
  // border: 1px solid red;
  width: 170px !important;
  display: inline-block;
}
.creditcode ::v-deep.el-form-item__label {
  // border: 1px solid red;
  width: 170px !important;
  display: inline-block;
}
.creditcode ::v-deep.el-form-item__content {
  // border: 1px solid red;
  //  width: 200px !important;
  // margin-left: 200px;
}
.creditcode ::v-deep input {
  //  border: 1px solid red;
}
.el-dialog {
  width: 25%;
  min-width: 25%;
}

.el-form-item {
  // border: 1px solid red;
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 5px;
  box-sizing: border-box;
  width: 90%;
  margin: 5px auto;
  height: 40px;
  ::v-deep .el-form-item__content {
    height: 40px;
  }
  ::v-deep .el-input .el-input__inner {
    border: 0 transparent !important;
    width: 270px;
    float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .el-form-item__label {
    //  width: 160px !important;
    // border: 1px solid red;
    text-align: left;
  }
  .el-cascader {
    width: 200px !important;
    float: right;
    height: 20px;
    line-height: 20px;
    // border: 1px solid red;
  }
}
.dobble {
  //  width: 170px !important;

  ::v-deep .el-form-item__label {
    //  border: 1px solid red;
    width: 170px !important;
    // border: 1px solid red;
    text-align: left;
  }
  a:nth-child(1) {
    color: royalblue;
    position: relative;
    left: 10px;
    z-index: 3000;
  }
  a:nth-child(2) {
    color: royalblue;
    position: relative;
    left: 30px;
    z-index: 10000;
  }
}

.avatar-uploader {
  width: 40px !important;
  float: right;
  height: 30px;
  line-height: 30px;
  //  border: 1px solid red;
}
.avatar {
  width: 30px;
  height: 30px;
}
::v-deep .el-upload.el-upload--text {
  //  border: 1px solid #ccc;
  width: 35px;
  height: 35px;
}
::v-deep .el-icon-plus.avatar-uploader-icon {
  line-height: 35px;
  font-size: 20px;
}
.bottom {
  width: 100%;
  margin-top: 30px;
  // border: 1px solid red;
  div {
    //  border: 1px solid red;
    width: 90%;
    margin: auto;
    .sureboom {
      width: 100% !important;
      height: 40px !important;
      margin-bottom: 10px !important;
      background-color: rgb(233, 79, 85) !important;
    }
    a {
      margin-left: 50px;
      color: royalblue;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
  .wan{
           width: 90%;
         }
   .wanwan{
          //  margin-top: 10px;
          position: relative;
          top: -14px;
          left: -8px;
           display: inline-block;
          //  border: 1px solid red;
           text-align: center;
         }
//  .lpiphonecode{
//   // border: 1px solid red;
//   position: relative;
//   .el-input{
//       position: absolute;
//     left: -30%;
//   }
//   .codeeee{
//      position: absolute;
//     left: 68%;
//     top: 2px;
//     line-height: 1px;
//     width: 94px !important;
//     height: 32px !important;
//     padding: 9px 15px !important;
//         border-radius: 20px !important;
//   }
// }
</style>
<template>
   <div class="result">
         <el-form ref="resultApp"  label-width="110px" >
    <el-form-item label="公司名称">
      <div class="inputDeep">
    <el-input v-model="resultApp.coname" class="attestation"></el-input>
    </div>
  </el-form-item>
  <!-- 主图 -->
       <el-form-item label="公司主图">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :headers="httpheader"
            :before-upload="beforeAvatarUpload"
            :disabled="disabled"
          >
            <img
              v-if="resultApp.coimage"
              :src="resultApp.coimage"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
    <!-- 姓名法定代表人 -->
   <el-form-item label="姓名法定代表人" class="peoplecode">
          <el-input v-model="resultApp.lpname"></el-input>
        </el-form-item>
    <!-- 姓名法定代表人身份证 -->
 <el-form-item label="姓名法定代表人身份证" class="identitycode">
          <el-input v-model="resultApp.idcard"></el-input>
        </el-form-item>
    <!-- 企业社会统一信用代码 -->
  <el-form-item label="企业社会统一信用代码" class="creditcode">
          <el-input v-model="resultApp.creditcode"></el-input>
        </el-form-item>
  <!-- 地址 -->
           
   <el-form-item label="公司地址">
             <el-cascader
            v-model="companyadress"
            :options="options"
            clearable
             @change="coaddressChange"
          ></el-cascader>
           <!-- <el-input v-model="resultApp.coaddress" class="attestation" :disabled="disabled"></el-input> -->
        </el-form-item>
  <!-- 街道 -->
  
   <el-form-item label="公司街道">
               
             <el-input v-model="resultApp.costreet" class="attestation" :disabled="disabled"></el-input>
        </el-form-item>
  <!-- 联系方式 -->
   <el-form-item label="联系方式">
          <el-input v-model="resultApp.cocontact" :disabled="disabled"></el-input>
        </el-form-item>
  <!-- 证件类型 -->
   <el-form-item label="证件类型">
          <div class="five">
            <el-radio v-model="resultApp.certtype" label="0">三证合一</el-radio>
            <el-radio v-model="resultApp.certtype" label="1">五证合一</el-radio>
          </div>
        </el-form-item>
  <!-- 营业执照 -->
       <el-form-item label="营业执照">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="cohandleAvatarSuccess"
            :headers="httpheader"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="resultApp.colicense"
              :src="resultApp.colicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
  <!-- 企业认证授权书 -->
      <el-form-item label="企业认证授权书" class="dobble">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="ahandleAvatarSuccess"
            :headers="httpheader"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="resultApp.authorization"
              :src="resultApp.authorization"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
  <!-- 注册资金 -->
    <el-form-item label="注册资金">
          <el-input v-model="resultApp.funding" class="wan"></el-input><span class="wanwan">万</span>
        </el-form-item>
  <!-- 主营 -->
  <el-form-item label="主营">
          <el-input v-model="resultApp.mainscope"></el-input>
        </el-form-item>
  </el-form>
    </div>
</template>
<script>
import cityCode from "../../../unitls/city_code.js"
import { Message } from "element-ui";
import imageZip from "../../../utils/imageZip"
import basecfg from "../../../utils/applocationCfg"
export default {
    data(){
         return {
             baseApi:basecfg.getBaseApi(),
      httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
      // 公司主图
       
      // 选中
      checked: true,
      // 申请表单
       
    resultAp:{
      certtype: '1',
      coaddress:["140000","140400","140421"],
    },
    options:[],
    //  label:"0",
      //  checked:false,
      // 三级联动
      // --------------地区----\
    
      // ----------------
      name: "上海钢铁贸有限公司",
      labelPosition: "left",
    };
    
    },
    props:{
      resultApp:{},
     cityoptions:"",
disabled:true,
companyadress:[],
    },
    created(){
    
   
      this.options = cityCode
    
    },
      
    methods:{
        // 限制大小
         beforeAvatarUpload(file) {
        const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
      return imageZip.beforeUpload(file)
      
    },
      // 更改数据转换
     
         //公司主图
    handleAvatarSuccess(res, file) {
      this.resultApp.coimage = URL.createObjectURL(file.raw);
     
     
    },
    // 营业执照
    cohandleAvatarSuccess(res, file) {
      this.resultApp.colicense = URL.createObjectURL(file.raw);
     
    },
    // 企业认证授权书
    ahandleAvatarSuccess(res, file) {
      this.resultApp.authorization = URL.createObjectURL(file.raw);
      
     
    },
         // 公司地址
     coaddressChange(value) {
      
        this.resultApp.coaddress =value
          
         
      },
      // 公司街道
       costreetChange(value) {
     
        this.resultApp.costreet = value.toString()
      }
    }
}
</script>
<style lang="scss" scoped>
.result{
  margin-top: -10px;
}
.el-form-item{
  
  // border: 1px solid red;
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 5px;
  box-sizing: border-box;
  width: 90%;
  margin:  auto;
 height: 44px;
 display: flex;
 ::v-deep .el-form-item__content{
   height: 40px;
 }

  ::v-deep .el-input .el-input__inner{
     border: 0 transparent !important;
    //  width: 200px;
     float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
    background-color:rgb(253,253,253) ;
    font-size: 16px;
  }
 ::v-deep  .el-cascader .el-input .el-input__inner{
     border:0px  !important;
    // border: 1px solid red !important;
    //  width: 200px;
     float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .el-form-item__label{
    // height: 10px;
    // line-height: 30px;
    // border: 1px solid red;
    // width: 140px !important;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    width: 190px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    // border: 1px solid red;
  
}
 
}

.five{
  // border: 1px solid red;
width: 190px;


    
}
::v-deep .avatar-uploader{
  
    width: 40px  !important;
    //  float: left;
    margin-left: 140px;
     height: 30px;
    line-height: 30px;
    margin-top: 3px;
    //  border: 1px solid red;

}
.avatar{
  width: 30px;
  height: 30px;
}
::v-deep .el-upload.el-upload--text{
        //  border: 1px solid #ccc;
         width: 35px;
         height: 35px;
      
     }  
    ::v-deep .el-icon-plus.avatar-uploader-icon{
           line-height: 35px;
           font-size: 20px;
         }
         .wan{
           width: 90%;
         }
         .wanwan{
          //  margin-top: 10px;
          position: relative;
          top: -14px;
          left: -8px;
           display: inline-block;
          //  border: 1px solid red;
           text-align: center;
         }
</style>
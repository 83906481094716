<template>
  <div>
    <!-- 未认证 -->
    <div class="unreviewed">
      <div class="top">
        <input
          placeholder="输入邀请码加入企业"
          v-model="checkCode"
          class="attestation"
          clearable
        />
        <el-button type="primary" @click="checkInvite">提交</el-button>
      </div>
      <div class="boot">
        <Createdia
          :createdialong="createdialong"
          @clear="clear"
          @createsure="createsure"
        ></Createdia>
        <el-button type="danger" @click="createqy">+ 创建企业</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import Createdia from "../entre/createidialog.vue";
import companyApi from "../../../api/companyApi";
import administrator from "../../../api/administrator.js";
import userApi from "../../../api/updataApi";
export default {
  data() {
    return {
      // 加入企业code邀请码
      checkCode: "",
      //   创建弹窗
      createdialong: false,
    };
  },
  components: {
    Createdia,
    administrator,
  },
  mounted() {
    this.getCompanySate();
  },
  methods: {
    // 获取当前企业状态
    getCompanySate() {
      companyApi.getCurrCompanySate((res) => {
        localStorage.setItem("companystate", this.companystate);
    
        if (res.data.code != 603) {
        
          if (res.data.result.state == 1) {
            this.$router.push("authenticated");
          } else {
            this.$router.push("audit");
          }
        }
      });
    },

    // 加入企业code邀请码
    checkInvite() {
      userApi.getUseridentityStates("", (res) => {
    
        if (res.data.result == false) {
          Message.error("请进行个人认证");
        } else {
          if (this.checkCode) {
            administrator.checkInviteCode({ code: this.checkCode }, (res) => {
          
              if (res.data.code == 200) {
                 this.$uweb.trackEvent("认证行为","认证成功","企业认证")
                Message.success("恭喜你加入企业成功");
                this.getCompanySate();
                this.$router.go(0)
              } else {
                Message.error(res.data.message);
              }
            });
          } else {
            Message.error("请输入正确的邀请码");
          }
        }
      });
    },
    //  创建企业
    createqy() {
       userApi.getUseridentityStates("",res=>{
       
          if(res.data.result==false){
                Message.error('请进行个人认证');
            
          }
          else{
              this.createdialong = true;
          }
        })
    },
    // 确定创建
    createsure(bool) {
     
      this.createdialong = bool;
      //  this.apply = true
      //  this.edit=true
      // this.padding=false
    },
    // 取消创建
    clear(bool) {
      this.dialogVisible = bool;

      this.createdialong = bool;
    },
  },
};
</script>
<style scoped lang="scss">
.unreviewed {
  margin-top: 50px;
}
.top {
  // border: 1px solid red;
  /* width: 270px; */
  height: 50px;
  width: 600px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: space-between;
  input {
    font-size: 20px;
    border: 0;
    outline: none;
    width: 80%;
    background-color: #fdfdfd;
  }
  button {
    // border: 1px solid red;
    width: 80px;
    height: 40px;
    background: #0171e3;
    border-radius: 5px;
  }
}
.boot {
  /* border: 1px solid red; */
  /* width:600px ; */
  height: 50px;
  margin-left: 200px;
  margin-top: 300px;
  display: flex;
  button {
    width: 130px;
    height: 45px;
    background: #e94f55;
    border-radius: 5px;
    font-size: 18px;
  }
  button:hover {
    color: white !important;
    background-color: rgb(233, 79, 85) !important;
  }
}
.boot >>> .el-button:nth-child(1) {
  width: 132px;
  height: 45px;
  background: #0171e3;
  border-radius: 5px;
  margin-right: 100px;
}
.boot >>> .el-button:nth-child(2) {
  width: 132px;
  height: 45px;
  background: #e94f55;
  border-radius: 5px;
}
</style>
<template>
  <div class="audite">
    <!-- 审核 -->
    <div class="waite" v-if="this.companystate == 0">
      <img src="../../../assets/home/dwait.png" alt="" />
      <p>待审核</p>
    </div>
    <div class="waite" v-if="this.companystate == 2">
      <img src="../../../assets/home/detil.png" alt="" />
      <div class="because">
        <p>已拒绝</p>
        <p class="bule" @click="cause">查看原因</p>
      </div>
    </div>
    <div class="waite" v-if="this.companystate == 4">
      <img src="../../../assets/home/dstate.png" alt="" />
      <div class="because">
        <p>已禁用</p>
        <p class="bule" @click="cause">查看原因</p>
      </div>
    </div>
    <Goodsure
      :addDialog="addDialog"
      :title="title"
      @del="del"
      @addsure="addsure"
      :resultmessag="resultmessag"
    ></Goodsure>

    <div
      class="result"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(253,253,253,0.8)"
    >
      <el-form ref="resultApp" label-width="110px">
        <el-form-item label="公司名称">
          <div class="inputDeep">
            <el-input
              v-model="resultApp.coname"
              class="attestation"
              :disabled="disabled"
            ></el-input>
          </div>
        </el-form-item>
        <!-- 主图 -->
        <el-form-item label="公司主图">
          <el-upload
            class="avatar-uploader"
            :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :headers="httpheader"  :on-progress = "oprogress"  
           :before-upload="beforeAvatarUpload"
            :disabled="disabled"
          >
            <img
              v-if="resultApp.coimage"
              :src="resultApp.coimage"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 姓名法定代表人 -->
        <el-form-item label="法定代表人" class="peoplecode">
          <el-input v-model="resultApp.lpname" :disabled="disabled"></el-input>
        </el-form-item>
        <!-- 姓名法定代表人身份证 -->
        <el-form-item label="法定代表人身份证" class="identitycode">
          <el-input v-model="resultApp.idcard" :disabled="disabled"></el-input>
        </el-form-item>
        <!-- 企业社会统一信用代码 -->
        <el-form-item label="企业社会统一信用代码" class="creditcode">
          <el-input
            v-model="resultApp.creditcode"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <!-- 地址 -->

        <el-form-item label="公司地址">
          <el-cascader
            v-model="resultApp.coaddress"
            :options="options"
            clearable
            @change="coaddressChange"
            :disabled="disabled"
          ></el-cascader>
          <!-- <el-input v-model="resultApp.coaddress" class="attestation" :disabled="disabled"></el-input> -->
        </el-form-item>
        <!-- 街道 -->

        <el-form-item label="公司街道">
          <el-input
            v-model="resultApp.costreet"
            class="attestation"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <!-- 联系方式 -->
        <el-form-item label="联系方式">
          <el-input
            v-model="resultApp.cocontact"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <!-- 证件类型 -->
        <el-form-item label="证件类型">
          <div class="five">
            <el-radio
              v-model="resultApp.certtype"
              label="0"
              :disabled="disabled"
              >三证合一</el-radio
            >
            <el-radio
              v-model="resultApp.certtype"
              label="1"
              :disabled="disabled"
              >五证合一</el-radio
            >
          </div>
        </el-form-item>
        <!-- 营业执照 -->
        <el-form-item label="营业执照">
          <el-upload
            class="avatar-uploader"
           :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="cohandleAvatarSuccess"
            :headers="httpheader"  :on-progress = "oprogress"  
          :before-upload="beforeAvatarUpload"
            :disabled="disabled"
          >
            <img
              v-if="resultApp.colicense"
              :src="resultApp.colicense"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 企业认证授权书 -->
        <el-form-item label="企业认证授权书" class="dobble">
          <el-upload
            class="avatar-uploader"
            :action="baseApi+ '/center/file/upload'"
            :show-file-list="false"
            :on-success="ahandleAvatarSuccess"
            :headers="httpheader"  :on-progress = "oprogress"  
         :before-upload="beforeAvatarUpload"
            :disabled="disabled"
          >
            <img
              v-if="resultApp.authorization"
              :src="resultApp.authorization"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 注册资金 -->
        <el-form-item label="注册资金">
          <el-input
            v-model="resultApp.funding"
            class="wan"
            :disabled="disabled"
          ></el-input
          ><span class="wanwan">万</span>
        </el-form-item>
        <!-- 主营 -->
        <el-form-item label="主营">
          <el-input
            v-model="resultApp.mainscope"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="bootem">
      <div class="editwo">
        <el-button
          @click="cancel"
          v-if="
            this.companystate == 0 ||
            this.companystate == 2
          "
          >取消申请</el-button
        >
        <el-button @click="reCreate" v-if="this.companystate == 2"
          >重新提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import cityCode from "../../../unitls/city_code.js";
import Result from "../entre/result.vue";
import Goodsure from "../entre/cause.vue";
// import Msgtip from "../entre/dialog.vue"
import companyApi from "../../../api/companyApi";
import imageZip from "../../../utils/imageZip"
import basecfg from "../../../utils/applocationCfg"

export default {
  data() {
    return {
        baseApi:basecfg.getBaseApi(),
      loading:false,
      companystate:null,
      httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
      options: [],
      label: "",
      addDialog: false,
      // 待审核
      disabled: this.$store.state.home.companystate == 0,
      // 企业信息
      resultApp: {},
      coimage: "",
      colicense: "",
      authorization: "",
      loading: false,
      title: "",
      resultmessag: [],
      resubmitdata:{}
    };
  },
  mounted(){
        this.getCompanySate();
     this.getCurrCompanyInfo();
    //  this.getCompanyInfo.
    this.options = cityCode;
   

  },
  created() {
   
  },
  components: {
    Result,
    Goodsure,
  },

  methods: {
         // 限制大小
         beforeAvatarUpload(file) {
        const isJPG = file.type.indexOf("image")!=-1;
       const isLt2M = file.size / 1024 / 1024 < 4;
     
        if (!isJPG) {
          Message.error('请正确上传图片格式!');
        }
        if (!isLt2M) {
         Message.error('上传头像图片大小不能超过 4MB!');
        }
        
       return isJPG && isLt2M;
      
    },
    // 上传时
    oprogress(){
      this.loading = true
    },
    // 查看详情
    del(bool) {
      this.addDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;

    },
    cause() {
      // console.log(this.$store.state.home.companystate);
      if (localStorage.getItem('companystate') == 2) {
        this.title = "拒绝原因";
        companyApi.getCompanyRefuseReason("", (res) => {
         
          if(res.data.code==200){
             this.addDialog = true; 
             this.resultmessag = res.data.result;
              console.log(  res.data.result);
          }
          else{
              Message.error(res.data.message);
          }
         
          
        });
      }
      if (localStorage.getItem('companystate') == 4) {
        this.title = "禁用原因";
        companyApi.getCompanyDisableReason("", (res) => {
           if(res.data.code==200){
             this.addDialog = true; 
             this.resultmessag = res.data.result;
              console.log( this.resultmessag);
          }
          else{
              Message.error(res.data.message);
          }
        });
      }
    },
    //   // 获取当前企业状态
    getCompanySate() {
      companyApi.getCurrCompanySate((res) => {
        this.companystate = res.data.result.state;
      
        localStorage.setItem("companystate", this.companystate);
        
        if (res.data.result.state != 0&&res.data.result.state != 2) {
          this.$router.push("entrepreneur");
        }
      });
    },

    // 获取当前企业信息
    getCurrCompanyInfo() {
      companyApi.getCurrCompanyInfo("", (res) => {
        this.resultApp = res.data.result;

        this.resultApp.coaddress = JSON.parse(this.resultApp.coaddress);

        this.resultApp.certtype = JSON.stringify(this.resultApp.certtype);
      });
    },
    // 取消申请
    cancel() {
      companyApi.cancelCreateCompany((res) => {
      
        this.$router.push("entrepreneur");
        this.$router.go(0)
      });
    },
    // 重新提交
    reCreate() {
      this.resetdata()
      this.loading = true;

      // this.resultApp.coimage = this.coimage;
      // this.resultApp.colicense = this.colicense;
      // this.resultApp.authorization = this.authorization;
      // this.resultApp.certtype = parseInt(this.resultApp.certtype)
     console.log(this.resubmitdata);
     // 身份证验证
          let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if(reg.test(this.resultApp.idcard) === false) 
       { 
         Message.error("身份证不合法")
              ; 
        return false; 
           } 

      // 手机号验证
             if(!/^1\d{10}$/.test(this.resultApp.cocontact)){
         Message.error('手机号格式不正确');
         return
      }
      companyApi.reCreateCompany(this.resubmitdata, (res) => {
        this.loading = false;
        if (res.data.code == 200) {
          Message({
            message: "重新提交成功",
            type: "success",
          });
          this.getCurrCompanyInfo();
        } else {
          Message.error("重新提交失败");
        }
      });
    },
    // 
    resetdata(){
     this.resubmitdata.authorization= this.resultApp.authorization 
     this.resubmitdata.certtype= parseInt(this.resultApp.certtype )
     this.resubmitdata.coaddress= this.resultApp.coaddress 
     this.resubmitdata.cocontact= this.resultApp.cocontact 
     this.resubmitdata.coimage= this.resultApp.coimage 
     this.resubmitdata.colicense= this.resultApp.colicense 
     this.resubmitdata.coname= this.resultApp.coname 
     this.resubmitdata.costreet= this.resultApp.costreet 
     this.resubmitdata.creditcode= this.resultApp.creditcode 
     this.resubmitdata.funding= this.resultApp.funding 
     this.resubmitdata.idcard= this.resultApp.idcard 
     this.resubmitdata.lpname= this.resultApp.lpname
     this.resubmitdata.lpphone= this.resultApp.lpphone
     this.resubmitdata.mainscope= this.resultApp.mainscope

    },
    //公司主图
    handleAvatarSuccess(res, file) {
      this.resultApp.coimage = URL.createObjectURL(file.raw);
      this.loading = false
     
      this.coimage = res.result;
    },
    // 营业执照
    cohandleAvatarSuccess(res, file) {
      this.resultApp.colicense = URL.createObjectURL(file.raw);
      
      this.loading = false
      this.colicense = res.result;
    },
    // 企业认证授权书
    ahandleAvatarSuccess(res, file) {
      this.resultApp.authorization = URL.createObjectURL(file.raw);
      this.authorization = res.result;
      this.loading = false
    },
    // 公司地址
    coaddressChange(value) {
     
      this.resultApp.coaddress = value;
     
    },
    // 公司街道
    costreetChange(value) {
     
      this.resultApp.costreet = value.toString();
    },
  },
};
</script>
<style scoped lang="scss">
.audite {
  // border: 1px solid red;
  margin-top: -20px;
}
.waite {
  .because {
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
    width: 90%;
    .bule {
      color: rgb(68, 122, 185);
      cursor: pointer;
    }
  }
  // border: 1px solid red;
  width: 90%;
  margin: 10px auto;
  background: #f7f8fa;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  img {
    margin-right: 20px;
  }
  p {
    font-size: 16px;
    color: rgb(233, 79, 85);
  }
}

.bootem {
  //  border: 1px solid red;
  width: 90%;
  margin: 0px auto;
  .apply {
    .el-col {
      font-size: 16px;
      // border: 1px solid red;
    }
    .el-row {
      // border: 1px solid red;
      border-bottom: 1px solid #ccc;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bg-purple {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 600;
        color: rgb(96, 98, 102);
      }
      .bg-purple-light {
        flex-grow: 230;
        font-size: 16px;
      }
      .bg-light {
        flex-grow: 20;
        height: 40px;
        line-height: 40px;
        .el-button {
          height: 30px;
          line-height: 5px;
          // border: 1px solid red;
          // margin-top: 5px;
          border: 1px solid rgb(233, 79, 85);
          color: white;
          background-color: rgb(233, 79, 85);
        }
        .el-button:hover {
          border: 1px solid rgb(233, 79, 85);
          color: white;
        }
      }
      div {
        // border: 1px solid red;
      }
    }
  }
}
.editwo {
  width: 90%;
  margin: 40px auto;
  // border: 1px solid red;
  display: flex;
  justify-content: center;

  .el-button {
    color: red;
    border: 1px solid red;
    width: 170px !important;
    height: 40px !important;
    background-color: white;
    margin: 5px auto;
    // margin-bottom: 20px;
  }
  .el-button:nth-child(2) {
    margin-left: 70px !important;
    background-color: rgb(233, 79, 85);
    color: white;
  }
}

.result {
  margin-top: -10px;
}
.el-form-item {
  // border: 1px solid red;
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 5px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
  height: 44px;
  display: flex;
  ::v-deep .el-form-item__content {
    height: 40px;
  }

  ::v-deep .el-input .el-input__inner {
    border: 0 transparent !important;
    //  width: 200px;
    float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
    background-color: rgb(253, 253, 253);
    font-size: 16px;
  }
  ::v-deep .el-cascader .el-input .el-input__inner {
    border: 0px !important;
    // border: 1px solid red !important;
    //  width: 200px;
    float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .el-form-item__label {
    // height: 10px;
    // line-height: 30px;
    // border: 1px solid red;
    // width: 140px !important;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    width: 190px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    // border: 1px solid red;
  }
}

.five {
  // border: 1px solid red;
  width: 190px;
}
::v-deep .avatar-uploader {
  width: 40px !important;
  //  float: left;
  margin-left: 140px;
  height: 30px;
  line-height: 30px;
  margin-top: 3px;
  //  border: 1px solid red;
}
.avatar {
  width: 30px;
  height: 30px;
}
::v-deep .el-upload.el-upload--text {
  //  border: 1px solid #ccc;
  width: 35px;
  height: 35px;
}
::v-deep .el-icon-plus.avatar-uploader-icon {
  line-height: 35px;
  font-size: 20px;
}
.wan {
  width: 90%;
}
.wanwan {
  //  margin-top: 10px;
  position: relative;
  top: -14px;
  left: -8px;
  display: inline-block;
  //  border: 1px solid red;
  text-align: center;
}
</style>
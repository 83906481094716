<template>
  <div>
    <!-- 已认证 -->
    <div class="reviewed">
      <div >
        <!-- <Result  :disabled="disabled" :resultApp="getCompanyInfo" ref="child" :cityoptions="cityoptions"></Result> -->
        <div class="result">
          <el-form ref="resultApp" label-width="110px">
            <el-form-item label="公司名称">
              <div class="inputDeep">
                <el-input
                  v-model="resultApp.coname"
                  disabled
                  class="attestation"
                ></el-input>
              </div>
            </el-form-item>
            <!-- 主图 -->
            <el-form-item label="公司主图">
              <el-upload
                class="avatar-uploader"
                :action="baseApi + '/center/file/upload'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :headers="httpheader"
                :disabled="disabled"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="resultApp.coimage"
                  :src="resultApp.coimage"
                  class="avatar avatarleft"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <!-- 姓名法定代表人 -->
            <el-form-item label="法定代表人" class="peoplecode">
              <el-input v-model="resultApp.lpname" disabled></el-input>
            </el-form-item>
            <!-- 姓名法定代表人身份证 -->
            <el-form-item label="法定代表人身份证" class="identitycode">
              <el-input v-model="resultApp.idcard" disabled></el-input>
            </el-form-item>
            <!-- 企业社会统一信用代码 -->
            <el-form-item label="企业社会统一信用代码" class="creditcode">
              <el-input v-model="resultApp.creditcode" disabled></el-input>
            </el-form-item>
            <!-- 地址 -->

            <el-form-item label="公司地址">
              <el-cascader
                v-model="resultApp.coaddress"
                :options="cityoptions"
                clearable
                @change="coaddressChange"
                :disabled="disabled"
                class="avatarleft"
              ></el-cascader>
              <!-- <el-input v-model="resultApp.coaddress" class="attestation" :disabled="disabled"></el-input> -->
            </el-form-item>
            <!-- 街道 -->

            <el-form-item label="公司街道">
              <el-input
                v-model="resultApp.costreet"
                class="attestation"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <!-- 联系方式 -->
            <el-form-item label="联系方式">
              <el-input
                v-model="resultApp.cocontact"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <!-- 证件类型 -->
            <el-form-item label="证件类型">
              <div class="five avatarleft">
                <el-radio v-model="resultApp.certtype" label="0" disabled
                  >三证合一</el-radio
                >
                <el-radio v-model="resultApp.certtype" label="1" disabled
                  >五证合一</el-radio
                >
              </div>
            </el-form-item>
            <!-- 营业执照 -->
            <el-form-item label="营业执照">
              <el-upload
                class="avatar-uploader"
                :action="baseApi + '/center/file/upload'"
                :show-file-list="false"
                :on-success="cohandleAvatarSuccess"
                :headers="httpheader"
                :before-upload="beforeAvatarUpload"
                disabled
              >
                <img
                  v-if="resultApp.colicense"
                  :src="resultApp.colicense"
                  class="avatar avatarleft"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <!-- 企业认证授权书 -->
            <el-form-item label="企业认证授权书" class="dobble">
              <!-- <el-upload
                class="avatar-uploader"
               :action="baseApi+ '/center/file/upload'"
                :show-file-list="false"
                :on-success="ahandleAvatarSuccess"
                :headers="httpheader"
                disabled
              >
                <img
                  v-if="resultApp.authorization"
                  :src="resultApp.authorization"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
              <el-link
                :href="resultApp.authorization"
                :underline="false"
                type="primary"
                style="margin-left: 170px"
              >
                下载
              </el-link>
            </el-form-item>
            <!-- 注册资金 -->
            <el-form-item label="注册资金">
              <el-input
                v-model="resultApp.funding"
                class="wan"
                disabled
              ></el-input
              ><span class="wanwan">万</span>
            </el-form-item>
            <!-- 主营 -->
            <el-form-item label="主营">
              <el-input v-model="resultApp.mainscope" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="bootem">
          <div class="apply">
            <el-row>
              <div class="grid-content bg-purple">旗舰店</div>
              <div class="grid-content bg-purple-light"></div>
              <div class="grid-content bg-light">
                <Msgtip
                  :dialogVisible="dialogVisible"
                  @clear="clear"
                  @sure="sure"
                ></Msgtip>
              
                 <span v-if="flagstate!=='未申请'" class="flagstate">{{flagstate}}</span>
                  <span class="lookflagstate"  v-show="flagstate=='已拒绝'" @click="lookcause"
                  >查看原因</span
                >
                  <el-button type="primary" plain   v-if="flagstate=='未申请'" @click="toRegister"
                  >申请旗舰店</el-button
                >
                    <el-button type="primary" plain   v-if="flagstate=='已拒绝'" @click="toRegister"
                  >重新申请旗舰店</el-button
                >
                 <Goodsure
      :addDialog="addDialog"
      :title="title"
      @del="del"
      @addsure="addsure"
      :resultmessag="resultmessag"
    ></Goodsure>
              </div>
            </el-row>
          </div>
          <div v-if="editpad">
            <div class="edit" v-if="edit">
              <el-button @click="editbj">编辑</el-button>
            </div>
            <div class="editwo" v-if="!edit">
              <el-button @click="canceledit">取消编辑</el-button>
              <el-button @click="resubmit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Msgtip from "../entre/dialog.vue";
import Result from "../entre/result.vue";
import Goodsure from "../entre/cause.vue";
import cityCode from "../../../unitls/city_code.js";
import companyApi from "../../../api/companyApi";

import updataApi from "../../../api/updataApi";
import { Message } from "element-ui";
import imageZip from "../../../utils/imageZip";
import basecfg from "../../../utils/applocationCfg";
export default {
  data() {
    return {
      baseApi: basecfg.getBaseApi(),
      httpheader: {
        token: JSON.parse(localStorage.getItem("Login_data")).token,
      },
      resultApp: {},
      resultmessag: [],
      addDialog: false,
        title: "",
      // 编辑
      edit: true,
      getCompanyInfo: "",
      // 旗舰店状态
flagstate:"",
      // 待审核

      padding: true,
      editpad: true,
      disabled: true,
      dialogVisible: false,
      cityoptions: [],
    };
  },

  components: {
    Msgtip,
    Result,
    Goodsure
  },
  mounted() {
    this.getCompanySate();
    this.getCurrCompanyInfo();
    this.cityoptions = cityCode;

    this.getCurrUserRole();
    this.getFlagShipState();
  },
  methods: {
       // 查看原因
    del(bool) {
      this.addDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;
     
    },
    // 查看旗舰店拒绝原因
    lookcause(){
    companyApi.getFlagShipRefuseReason({id: this.resultApp.uuid}, (res) => {
        
           
          if(res.data.code==200){
            this.addDialog = true; 
             this.title = "拒绝原因";
          this.resultmessag = res.data.result;
          }
          else{
             Message.error(res.data.message);
          }
         
          
        });
    },
    // 限制大小
    beforeAvatarUpload(file) {
      const isJPG = file.type.indexOf("image") != -1;
      const isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        Message.error("请正确上传图片格式!");
      }
      if (!isLt2M) {
        Message.error("上传头像图片大小不能超过 4MB!");
      }
      return imageZip.beforeUpload(file);
    },
    //   // 获取当前企业状态
    getCompanySate() {
      companyApi.getCurrCompanySate((res) => {
        this.companystate = res.data.result.state;
        localStorage.setItem("companystate", this.companystate);
      
        if (res.data.result.state != 1) {
          this.$router.push("entrepreneur");
        }
      });
    },
    // 获取当前角色状态
    getCurrUserRole() {
      updataApi.getCurrUserRole("", (res) => {
        if (res.data.result.code == "companyOrdinary") {
          this.editpad = false;
        } else {
          this.editpad = true;
        }
      });
    },

    // 获取当前企业信息
    getCurrCompanyInfo() {
      companyApi.getCurrCompanyInfo("", (res) => {
        this.resultApp = res.data.result;
        this.resultApp.coaddress = JSON.parse(this.resultApp.coaddress);
        this.resultApp.certtype = this.resultApp.certtype + "";
      });
    },
    // 获取旗舰店状态
    getFlagShipState() {
      companyApi.getFlagShipState("", (res) => {
        if (res.data.code == 200) {
         
          this.flagstate = res.data.result
        }
      });
    },
    //   取消弹窗
    clear(bool) {
      this.dialogVisible = bool;
      this.pdialogVisible = bool;
      this.createdialong = bool;
    },
    // 确定弹窗
    sure(bool) {
      this.dialogVisible = bool;
    },
    // 取消编辑
    canceledit() {
      this.edit = true;
      this.disabled = true;
    },
    // 提交
    resubmit() {
    
      // 身份证验证
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(this.resultApp.idcard) === false) {
        Message.error("身份证不合法");
        return false;
      }

      // 手机号验证
      if (!/^1\d{10}$/.test(this.resultApp.cocontact)) {
        Message.error("手机号格式不正确");
        return;
      }
   
      companyApi.updateCompany(
        {
          address: this.resultApp.coaddress,
          contact: this.resultApp.cocontact,
          image: this.resultApp.authorization,
          street: this.resultApp.costreet,
        },
        (res) => {
        
          if (res.data.code == 200) {
            Message({
              message: "重新提交成功",
              type: "success",
            });
            this.edit = true;
            this.disabled = true;
          } else {
            Message.error("修改失败");
          }
        }
      );
      this.$emit("changeComponent1Data", true);
    },
    // 编辑
    editbj() {
      this.edit = false;
      this.disabled = false;
    },
    //申请旗舰店点击弹框
    toRegister() {
      this.dialogVisible = true;
     
    },

    //公司主图
    handleAvatarSuccess(res, file) {
      this.resultApp.coimage = URL.createObjectURL(file.raw);
     
    },
    // 营业执照
    cohandleAvatarSuccess(res, file) {
      this.resultApp.colicense = URL.createObjectURL(file.raw);
    
    },
    // 企业认证授权书
    ahandleAvatarSuccess(res, file) {
      this.resultApp.authorization = URL.createObjectURL(file.raw);
    },
    // 公司地址
    coaddressChange(value) {
     
      this.resultApp.coaddress = value;
    
    },
    // 公司街道
    costreetChange(value) {
   
      this.resultApp.costreet = value.toString();
    },
  },
};
</script>
<style scoped lang="scss">
.flagstate{
  font-size: 14px;
  color: rgb(96,98,102);
  margin: 0 5px;
} 
.lookflagstate{
   font-size: 14px;
   color: rgb(64,158,255);
cursor: pointer;

}
.reviewed {
  // border: 1px solid red;
  width: 600px;
  height: 470px;
  margin-top: -10px;
  font-size: 20px;
  // padding: 10px;
  .waite {
    // border: 1px solid red;
    width: 90%;
    margin: 10px auto;
    background: #f7f8fa;
    display: flex;
    height: 50px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    img {
      margin-right: 20px;
    }
    p {
      font-size: 16px;
      color: rgb(233, 79, 85);
      
    }
  }
}
.bootem {
  //  border: 1px solid red;
  width: 90%;
  margin: 0px auto;
  .apply {
    .el-col {
      font-size: 16px;
      // border: 1px solid red;
    }
    .el-row {
      // border: 1px solid red;
      border-bottom: 1px solid #ccc;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bg-purple {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 600;
        color: rgb(96, 98, 102);
      }
      .bg-purple-light {
        flex-grow: 230;
        font-size: 16px;
      }
      .bg-light {
        flex-grow: 9;
        height: 40px;
        line-height: 40px;
        .el-button {
          height: 30px;
          line-height: 5px;
          // border: 1px solid red;
          // margin-top: 5px;
          border: 1px solid rgb(233, 79, 85);
          color: white;
          background-color: rgb(233, 79, 85);
        }
        .el-button:hover {
          border: 1px solid rgb(233, 79, 85);
          color: white;
        }
      }
      div {
        // border: 1px solid red;
      }
    }
  }
}
.edit {
  width: 31%;
  margin: 40px auto;
  //  border: 1px solid red;
  .el-button {
    border: 1px solid red;
    color: red;
    width: 170px !important;
    height: 40px !important;
    line-height: 5px;
    margin: 0 auto;
    background-color: white;
    margin-bottom: 20px;
  }
}
.editwo {
  width: 90%;
  margin: 40px auto;
  // border: 1px solid red;
  .el-button {
    color: red;
    border: 1px solid red;
    width: 170px !important;
    height: 40px !important;
    background-color: white;
    margin: 0;
    margin-bottom: 20px;
  }
  .el-button:nth-child(2) {
    margin-left: 70px !important;
    background-color: rgb(233, 79, 85);
    color: white;
  }
}
// result
.result {
  margin-top: -10px;
}
.el-form-item {
  // border: 1px solid red;
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 5px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
  height: 44px;
  display: flex;
  ::v-deep .el-form-item__content {
    height: 40px;
    
    width: 368px;
    margin-left: 0 !important;
  }

  ::v-deep .el-input .el-input__inner {
    border: 0 transparent !important;
    width: 368px;
      overflow: hidden;/*超出部分隐藏*/
            white-space: nowrap;/*不换行*/
            text-overflow:ellipsis;/*超出部分文字以...显示*/
    float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
    background-color: rgb(253, 253, 253);
    font-size: 16px;
  }
  ::v-deep .el-cascader .el-input .el-input__inner {
    border: 0px !important;
    // border: 1px solid red !important;
    width: 240px;
    float: right;
    //  border: 1px solid red;
    text-align: right;
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .el-form-item__label {
    // height: 10px;
    // line-height: 30px;
    // border: 1px solid red;
    // width: 140px !important;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    width: 190px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    // border: 1px solid red;
  }
}

.five {
  // border: 1px solid red;
  width: 230px;
}
::v-deep .avatar-uploader {
  width: 40px !important;
  //  float: left;
  margin-left: 200px;
  height: 30px;
  line-height: 30px;
  margin-top: 3px;
  //  border: 1px solid red;
}
.avatar {
  width: 30px;
  height: 30px;
  
}
.avatarleft{
  margin-left: 105px;
}
::v-deep .el-upload.el-upload--text {
  //  border: 1px solid #ccc;
  width: 35px;
  height: 35px;
}
::v-deep .el-icon-plus.avatar-uploader-icon {
  line-height: 35px;
  font-size: 20px;
}
.wan {
  width: 90%;
}
.wanwan {
  //  margin-top: 10px;
  position: relative;
  top: -14px;
  left: -8px;
  display: inline-block;
  //  border: 1px solid red;
  text-align: center;
}
</style>
<template>
     <div class="tip">
    <el-dialog title="确认申请成为旗舰店？"
               :visible="dialogVisible"
               width="32%"
              :before-close="clear"
               :close-on-click-modal="false"
               >
          <div class="center">
            <p>月企业流水：</p>
         <el-input v-model="start" ></el-input>——
         <el-input v-model="end" ></el-input>
 <p>万</p>
          </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary"
                   @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from "element-ui";
import companyApi from "../../../api/companyApi";
export default {
    data () {
    return {
      start:"",
      end:""
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    clear () {
      this.$emit('clear', false);
       this.start=""
          this.end=""
    },
    sure () {
      if(parseInt(this.start)>parseInt(this.end)){
        //  Message.error("金额不能为空")
          Message.error("请正确输入金额")
          this.start=""
          this.end=""
       }
   else if(parseInt(this.start)>=0&&parseInt(this.end)>=0&&parseInt(this.start)<=parseInt(this.end)){
      companyApi.addFlagshipRecord({low:this.start,high:this.end},res=>{
        
             if(res.data.code==200){
            Message({
          message: '恭喜你，申请成功',
          type: 'success'
        });   
       
         this.$emit('sure', false);
     
          
          this.$parent.$parent.getFlagShipState()
       }
             
     
       else{
           Message.error(res.data.message)
       }
       }
     )
     }
     else{
         Message.error("金额不能为空")
     }
    }
  }
}
</script>
<style lang="scss" >
.dialog-footer{
  display: flex;
}
.tip{
.el-dialog{
  text-align: center;
  // border: 1px solid red;
//  margin-top: 300px;
height: 350px ;
.el-dialog__footer {
   margin-top: 30px;
   display: flex;
   justify-content: space-between;
    box-sizing: border-box;
    //  border: 1px solid red;
     margin-left: 0px;
      //  border:1px solid red;
       margin: 20px auto;
       width: 80%;
      .el-button:nth-child(1) { 
     color:red;
     border:1px solid red;
     height: 35px;
     line-height: 10px;
     background-color: white;
     }
       .el-button:nth-child(2) { 
     color:white;
     border:1px solid red;
    background-color: rgb(233,79,85);
     height: 35px;
     line-height: 10px;
     }
     .el-button{
        //  border: 1px solid red;
         width: 100px;
         height: 45px;
         margin-left: 60px;
     }
}
}
.center{
  display: flex;
  width: 80%;
  margin: 0 auto;
  // border: 1px solid red;
  .el-input{
    width: 80px !important;
    margin: 0 5px;
  }
}
}
</style>